import RelationToOneField from '@/shared/fields/relation-to-one-field';
import { ArchiveService } from '@/modules/archive/archive-service';

export class WorkspaceTypeField {
    static relationToOne(name, label, options) {
        return new RelationToOneField(
            name,
            label,
            () => {
                return new Promise((resolve) => {
                    ArchiveService.listWorkspaces()
                    .then((data) => {
                        resolve(data.data);
                    })
                })
            },
            (record) => {
                if (!record) {
                    return '';
                }
                return {
                    value: record.id,
                    text: record.name,
                };
            },
            options,
        );
    }
}
